import React from 'react'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'
import ButtonBlock from 'components/shared/ButtonBlock'
import {AccordionStoryblok} from 'types/storyblok-types'
import {renderRichText, storyblokEditable} from '@storyblok/react'
import {TextBlock} from './Text'
import linkTypeChecker from 'util/linkTypeChecker'

export type AccordionItem = {
  uid: string
  title: string
  content: string
  cta_title?: string
  cta_link?: string
  cta_target?: string
}

type AccordionProps = {
  items: AccordionItem[]
}

export const AccordionBlock = ({items}: AccordionProps) => {
  const [open, setOpen] = React.useState(1)
  const handleOpen = (value: React.SetStateAction<number>) =>
    setOpen(open === value ? 0 : value)

  return (
    <>
      <div className="flex flex-col items-start justify-center">
        {items.map((item, index) => (
          <Accordion
            key={item.uid}
            open={open === index + 1}
            className={`before:absolute before:-left-0 before:h-full before:w-1 before:bg-dats-s1 ${
              open === index + 1 ? 'before:bg-dats-s1' : 'before:bg-dats-s9'
            } ${index === 0 ? 'before:rounded-t-full' : ''} ${
              index === items.length - 1 ? 'before:rounded-b-full' : ''
            } pl-8`}
          >
            <AccordionHeader
              onClick={() => handleOpen(index + 1)}
              className={`border-0 ${
                open === index + 1
                  ? 'font-rubik text-2xl font-bold text-dats-s6'
                  : 'font-rubik text-2xl font-bold text-dats-s7'
              } ${index === 0 ? 'py-0' : 'pt-[20px]'} hover:text-dats-s6`}
            >
              {item.title}
            </AccordionHeader>
            <AccordionBody className="pt-0 font-body text-base">
              <TextBlock
                innerHtml={item.content}
                includeAccordionPadding={
                  item.cta_title && item.cta_link ? true : false
                }
              />

              {item.cta_title && item.cta_link ? (
                <div className="pb-[20px]">
                  <ButtonBlock
                    title={item.cta_title}
                    type="button"
                    styling="secondary"
                    className="mt-0"
                    cta_url={item.cta_link}
                    cta_target={item.cta_target}
                  />
                </div>
              ) : null}
            </AccordionBody>
          </Accordion>
        ))}
      </div>
    </>
  )
}

type AccordionContainerProps = {
  blok: AccordionStoryblok
}
const AccordionContainer = ({blok}: AccordionContainerProps) => {
  const items: AccordionItem[] = blok.items
    ? blok.items?.flatMap(item => {
        return {
          uid: item._uid,
          title: item.title,
          content: renderRichText(item.content) ?? '',
          cta_title: item.cta_title,
          cta_link: linkTypeChecker(item.cta_link),
          cta_target: item.cta_link?.target,
        }
      })
    : []

  return (
    <div {...storyblokEditable(blok)}>
      <AccordionBlock items={items} />
    </div>
  )
}
export default AccordionContainer
